var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AutomaticRules" },
    [
      _vm.form.vacationTypeCode === "NXJ"
        ? _c(
            "a-form-model-item",
            { attrs: { label: "自动发放规则" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.form.vacationGrantRuleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vacationGrantRuleType", $$v)
                    },
                    expression: "form.vacationGrantRuleType",
                  },
                },
                _vm._l(_vm.grantRuleList, function (convert) {
                  return _c(
                    "a-radio",
                    {
                      key: convert.codeKey,
                      style: _vm.inlineRadioStyle,
                      attrs: { value: convert.codeKey },
                    },
                    [_vm._v(" " + _vm._s(convert.content) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.form.vacationTypeCode === "NXJ"
        ? _c("div", { staticClass: "AutomaticRules-content" }, [
            _vm.form.vacationGrantRuleType === "1"
              ? _c(
                  "div",
                  { staticClass: "grantFixedDays" },
                  [
                    _vm._v(" 每年自动发放假期"),
                    _c("CbNumber", {
                      staticClass: "large-input",
                      attrs: { "decimal-separator": false },
                      model: {
                        value: _vm.form.grantFixedDays,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantFixedDays", $$v)
                        },
                        expression: "form.grantFixedDays",
                      },
                    }),
                    _vm._v("天 "),
                  ],
                  1
                )
              : ["2", "3"].includes(_vm.form.vacationGrantRuleType)
              ? _c(
                  "div",
                  _vm._l(_vm.form.amountSteps, function (it, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "set-rules" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.form.vacationGrantRuleType === "2"
                                ? "工"
                                : "司"
                            ) +
                            "龄满"
                        ),
                        _c("CbNumber", {
                          staticClass: "number-input",
                          attrs: { disabled: !index, suffix: "年" },
                          model: {
                            value: it.startValue,
                            callback: function ($$v) {
                              _vm.$set(it, "startValue", $$v)
                            },
                            expression: "it.startValue",
                          },
                        }),
                        _c(
                          "a-select",
                          {
                            staticClass: "small-input",
                            model: {
                              value: it.containStartFlag,
                              callback: function ($$v) {
                                _vm.$set(it, "containStartFlag", $$v)
                              },
                              expression: "it.containStartFlag",
                            },
                          },
                          [
                            _c("a-select-option", { attrs: { value: "1" } }, [
                              _vm._v("<"),
                            ]),
                            _c("a-select-option", { attrs: { value: "2" } }, [
                              _vm._v("≤"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(
                          _vm._s(
                            _vm.form.vacationGrantRuleType === "2" ? "工" : "司"
                          ) + "龄 "
                        ),
                        _c(
                          "a-select",
                          {
                            staticClass: "small-input",
                            model: {
                              value: it.containEndFlag,
                              callback: function ($$v) {
                                _vm.$set(it, "containEndFlag", $$v)
                              },
                              expression: "it.containEndFlag",
                            },
                          },
                          [
                            _c("a-select-option", { attrs: { value: "1" } }, [
                              _vm._v("<"),
                            ]),
                            _c("a-select-option", { attrs: { value: "2" } }, [
                              _vm._v("≤"),
                            ]),
                          ],
                          1
                        ),
                        _c("CbNumber", {
                          staticClass: "number-input margin-left-none",
                          attrs: { suffix: "年", "decimal-separator": true },
                          on: {
                            change: function ($event) {
                              return _vm.change(it.endValue, index)
                            },
                          },
                          model: {
                            value: it.endValue,
                            callback: function ($$v) {
                              _vm.$set(it, "endValue", $$v)
                            },
                            expression: "it.endValue",
                          },
                        }),
                        _vm._v("，发放假期 "),
                        _c("CbNumber", {
                          staticClass: "number-input",
                          attrs: { suffix: "天", "decimal-separator": true },
                          model: {
                            value: it.amount,
                            callback: function ($$v) {
                              _vm.$set(it, "amount", $$v)
                            },
                            expression: "it.amount",
                          },
                        }),
                        !index
                          ? _c("a-button", {
                              attrs: { type: "iconBtn", icon: "add-one" },
                              on: {
                                click: function ($event) {
                                  return _vm.form.amountSteps.push({
                                    containStartFlag: "1",
                                    containEndFlag: "1",
                                    startValue:
                                      _vm.form.amountSteps[
                                        _vm.form.amountSteps.length - 1
                                      ].endValue,
                                  })
                                },
                              },
                            })
                          : _c("a-button", {
                              staticClass: "deleted",
                              attrs: { type: "iconBtn", icon: "close-small" },
                              on: {
                                click: function ($event) {
                                  return _vm.form.amountSteps.splice(index, 1)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : ["4", "5"].includes(_vm.form.vacationGrantRuleType)
              ? _c(
                  "div",
                  { staticClass: "set-rules" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.form.vacationGrantRuleType === "4" ? "工" : "司"
                        ) +
                        "龄满"
                    ),
                    _c("CbNumber", {
                      staticClass: "increas-input",
                      attrs: { suffix: "年", "decimal-separator": true },
                      model: {
                        value: _vm.form.grantMinWorkAge,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantMinWorkAge", $$v)
                        },
                        expression: "form.grantMinWorkAge",
                      },
                    }),
                    _vm._v("，可以休假 "),
                    _c("CbNumber", {
                      staticClass: "increas-input",
                      attrs: { suffix: "天", "decimal-separator": true },
                      model: {
                        value: _vm.form.grantMinDays,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantMinDays", $$v)
                        },
                        expression: "form.grantMinDays",
                      },
                    }),
                    _vm._v(
                      "，" +
                        _vm._s(
                          _vm.form.vacationGrantRuleType === "4" ? "工" : "司"
                        ) +
                        "龄每增加 "
                    ),
                    _c("CbNumber", {
                      staticClass: "increas-input",
                      attrs: { suffix: "年", "decimal-separator": true },
                      model: {
                        value: _vm.form.grantWorkAgeStep,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantWorkAgeStep", $$v)
                        },
                        expression: "form.grantWorkAgeStep",
                      },
                    }),
                    _vm._v("，假期增加 "),
                    _c("CbNumber", {
                      staticClass: "increas-input",
                      attrs: { suffix: "天", "decimal-separator": true },
                      model: {
                        value: _vm.form.grantDaysStep,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantDaysStep", $$v)
                        },
                        expression: "form.grantDaysStep",
                      },
                    }),
                    _vm._v("，假期最高上限 "),
                    _c("CbNumber", {
                      staticClass: "number-input margin-left-none",
                      attrs: { suffix: "天", "decimal-separator": true },
                      model: {
                        value: _vm.form.grantMaxDays,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "grantMaxDays", $$v)
                        },
                        expression: "form.grantMaxDays",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm.form.vacationTypeCode === "TXJ"
        ? _c(
            "div",
            [
              _vm._v(" 按加班时长"),
              _c("CbNumber", {
                staticClass: "number-input",
                attrs: { disabled: true, "defult-val": "1" },
              }),
              _vm._v(": "),
              _c("CbNumber", {
                staticClass: "number-input",
                attrs: { "decimal-separator": true },
                model: {
                  value: _vm.form.overtimeConvertScale,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "overtimeConvertScale", $$v)
                  },
                  expression: "form.overtimeConvertScale",
                },
              }),
              _vm._v("补偿调休时长，自动发放 "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }